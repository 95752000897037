import React, {useEffect, useRef, useState} from 'react';
import { call, put } from "redux-saga/effects";
import {useDispatch, useSelector,connect} from "react-redux";
import Room from "./components/Room";
// import Actions from "../../variables/actions/Actions";
import io from 'socket.io-client'
import {useHistory} from 'react-router-dom'
// import SkeletonLoader from "tiny-skeleton-loader-react";


const Conversations = ({match,defaultRooms}) => {

    
    const dispatch = useDispatch();
    const scrollContainer = useRef(null);
    let [rooms,setRooms] = useState(defaultRooms.rooms)
    let [room,setRoom] = useState(defaultRooms.room)
    let [roomsNumber,setRoomsNumber] = useState(0)
    let [firstRoomID,setFirstRoomID] = useState(null)
    let [loading,setLoading] = useState(true)
    const [scrollHeight, setScrollHeight] = useState(0);
    const [tab, setTab] = useState(0);
    const history = useHistory();


    const socket = io('https://nurse-job.herokuapp.com/',{
        timeout:3000,
        autoConnect:true
    });
    let token = localStorage.getItem('token')
    socket.on('connect', () => {
        socket.emit('authenticate', {token});
    })
    // console.log('socket',socket);
    window.socket = socket
    // console.log('this is match');
    useEffect(() => {
        // console.log('this is room111');
        setTimeout(()=>{
            
        const urlParams = new URL(window.location.href).searchParams;
        const key = urlParams.get('key');

        socket.emit('list-rooms', {limit:10});
        socket.on('list-rooms', (data)=>{
            // console.log(data,'this is room',data.rooms?.length);
            if(data.rooms?.length!==0){
                if(key !== null){
                    socket.emit('join-room',{roomID:key})
                  //  history.push("/messages");
                }else{
                    socket.emit('join-room',{roomID:data.rooms[data.rooms.length-1]?._id})

                }

                socket.on('join-room',(data)=>{
                    // console.log('gii',data);
                    dispatch({ type: 'JOIN_ROOM_RESULT', data })
                })
            }
            setRooms(data.rooms)
            setRoomsNumber(data.rooms.length)
            // console.log(data.rooms[data.rooms.length - 1].lastUpdate)
            setFirstRoomID(data.rooms?.length > 0 ? data.rooms[data.rooms.length - 1].lastUpdate : null)
            setLoading(false)
        });

    },1000);
       

        if (scrollContainer.current?.scrollTop === 0) scrollContainer.current.scrollTop = scrollHeight
        // console.log(data,'this is room111');
    }, []);

    const onScroll = () => {
        setScrollHeight(scrollContainer.current.scrollHeight);
        if (scrollContainer.current.scrollTop >= (scrollContainer.current.scrollHeight - scrollContainer.current.offsetHeight))
            dispatch({type: 'MORE_ROOMS', roomID: firstRoomID});
    };

    const Rooms = rooms?.map(room => {
        return <Room room={room} socket={socket} key={room._id} /> 
    });

    const Notice = () => {
        if (!tab && rooms?.length === 0) return (
            <div className="notice-text">
                There are no rooms.<br/>Search for someone to start a conversation!
            </div>
        );

        return null;
    };


    return (
       
        
        <div className="list-wrapper">
            <Notice/>
            <div className="list" ref={scrollContainer} onScroll={onScroll} >
                {Rooms}
            </div>
        </div>
    
    );
};
const mapStateToProps = (state) => (()=>{
    // console.log(state,'this is conversation ');
    return{
    defaultRooms:state
}})
export default connect(
    mapStateToProps
  )(Conversations)

